import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { IMAGE_SERVER_URL } from "@/store/shared/ApiRequest";
import UsersTable from "@/components/admin/users/UsersTable.vue";
import AdditionalAddresses from "@/components/admin/dialogs/Group/AdditionalAddresses.vue";
import UserModule from "@/store/module/user/UserModule";
import GroupModule from "@/store/module/shared/GroupModule";
let GroupPage = class GroupPage extends Vue {
    constructor() {
        super(...arguments);
        this.additionalAddresses = {
            dialog: false,
        };
        this.photos = {
            items: [],
        };
        this.documentsTable = {
            selected: [],
            headers: [
                {
                    text: "Название",
                    sortable: true,
                    value: "name",
                },
                {
                    text: "Ссылка",
                    sortable: false,
                    value: "link",
                },
            ],
            items: [],
            search: "",
            loading: true,
            pagesCount: 0,
            itemsPerPage: 5,
            page: 1,
            sortBy: "name",
            showExpand: false,
            singleExpand: false,
        };
        this.group = null;
        this.participantsHeaders = [
            {
                text: "Амплуа",
                sortable: true,
                value: "amplua",
            },
            {
                text: "Фамилия",
                sortable: true,
                value: "surname",
            },
            {
                text: "Имя",
                sortable: true,
                value: "name",
            },
            {
                text: "Отчество",
                sortable: true,
                value: "patronymic",
                align: "center",
            },
            {
                text: "Дата рождения",
                sortable: true,
                value: "birthdate",
            },
            {
                text: "№ билета",
                sortable: true,
                value: "idRfsl",
            },
            {
                text: "Срок действия",
                sortable: true,
                value: "expiryDate",
            },
            {
                text: "Дата вступления",
                sortable: true,
                value: "entryDate",
            },
            { text: "", value: "data-table-expand" },
        ];
    }
    get user() {
        return this.$store.getters[UserModule.types.getters.USER];
    }
    get avatar() {
        if (!this.group) {
            return `${IMAGE_SERVER_URL}/public/archery-event-placeholder.jpg`;
        }
        return `${IMAGE_SERVER_URL}/public/${this.group.logo}`;
    }
    get description() {
        return this.group && this.group.description ? this.group.description : "Отсутсвует";
    }
    get participants() {
        return {
            participants: this.group.participants
                .filter((participant) => (participant.participantId === null ? false : true) &&
                !participant.participant.isPremoderated &&
                !participant.participant.isStopped)
                .map((participant) => ({ ...participant.participant, amplua: participant.amplua.name })),
        };
    }
    get profile() {
        if (!this.group) {
            return {
                main: [],
                additional: [],
            };
        }
        return {
            main: [
                { title: "Полное название", value: this.group.fullName },
                { title: "Короткое название", value: this.group.shortName },
                { title: "Код", value: this.group.code },
                { title: "Участников", value: () => this.group.participants.length },
            ],
            additional: [
                {
                    title: "Федеральный округ",
                    value: this.group.contacts.address === null ? "Не указан" : this.group.contacts.address.region.district.name,
                    isTree: false,
                },
                {
                    title: "Регион",
                    value: this.group.contacts.address === null ? "Не указан" : this.group.contacts.address.region.name,
                    isTree: false,
                },
                {
                    title: "Город",
                    value: this.group.contacts.address !== null && this.group.contacts.address.city !== null
                        ? this.group.contacts.address.city.name
                        : "Не указан",
                    isTree: false,
                },
                {
                    title: "Адрес",
                    value: this.group?.contacts?.addressText ? this.group.contacts.addressText : "Не указан",
                    isTree: false,
                },
                { title: "Email", value: this.group.contacts.email, isTree: false },
                { title: "Телефон", value: this.group.contacts.phone, isTree: false },
                {
                    title: "Сайт",
                    value: this.group.contacts?.website ? this.group.contacts?.website : "Не указан",
                    isTree: false,
                },
            ],
        };
    }
    async mounted() {
        const groupId = parseInt(this.$route.params.id, 10);
        this.group = await this.$store.dispatch(GroupModule.types.actions.LOAD_GROUP, { id: groupId });
        this.group.documents.map((doc) => {
            this.documentsTable.items.push({
                ...doc,
                link: `${IMAGE_SERVER_URL}/documents/${doc.src}`,
            });
        });
        this.photos.items = this.group.photos.map((item) => `${IMAGE_SERVER_URL}/public/${item.src}`);
        this.documentsTable.loading = false;
    }
};
GroupPage = __decorate([
    Component({
        components: {
            UsersTable,
            AdditionalAddresses,
        },
    })
], GroupPage);
export default GroupPage;
